import { useStaticQuery, graphql } from 'gatsby'

export const cookieContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        page(id: "904", idType: DATABASE_ID) {
          AllPageElements {
            pageelements {
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Seodata {
                description
                fieldGroupName
                keyword
                title
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Headerdescriptionblock {
                content
                title
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Contentblockdata {
                contentList {
                  title
                  ptext {
                    paragraf
                  }
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Listblockdata {
                fieldGroupName
                title
                listContent {
                  ... on WPGraphQL_Page_Allpageelements_Pageelements_Listblockdata_ListContent_Description {
                    description
                    fieldGroupName
                  }
                  ... on WPGraphQL_Page_Allpageelements_Pageelements_Listblockdata_ListContent_List {
                    fieldGroupName
                    list {
                      content
                      title
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Typeblockdata {
                types {
                  ... on WPGraphQL_Page_Allpageelements_Pageelements_Typeblockdata_Types_Title {
                    fieldGroupName
                    title
                    titleBold
                  }
                  ... on WPGraphQL_Page_Allpageelements_Pageelements_Typeblockdata_Types_Description {
                    description
                    fieldGroupName
                  }
                  ... on WPGraphQL_Page_Allpageelements_Pageelements_Typeblockdata_Types_List {
                    fieldGroupName
                    list {
                      islink
                      description
                      title
                    }
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return [
    data.wpgraphql.page.AllPageElements.pageelements
  ]
}