import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { Link } from 'gatsby'
import Col from 'react-bootstrap/Col'

// assets
import BackIcon from '@images/back.svg'

// utils
import colors from '@styles/js/colors'

const Hero = styled(({ data, className }) => (
  <div
    className={`${className} bg-cerulean-blue d-flex align-items-center position-relative`}
  >
    <Container>
      <Row>
        <Col xs={12} lg={7}>
          <div className="wrapper">
            <Link className="d-flex align-items-center back" to="/careers/">
              <BackIcon />
              <span>Back</span>
            </Link>
            <h1 className="text-white">{data.title}</h1>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
))`
  position: relative;
  padding: 52px 7px 130px 8px;

  @media (min-width: 768px) {
    padding: 52px 24px 148px;
  }

  @media (min-width: 1440px) {
    padding: 87px 0 175px;
  }

  &:before {
    background: #f5c141;
    bottom: -48px;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    content: '';
    height: 128px;
    left: 0;
    position: absolute;
    width: 148px;
    z-index: 1;

    @media (min-width: 768px) {
      bottom: -90px;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      height: 141px;
      width: 160px;
    }

    @media (min-width: 1440px) {
      bottom: -240px;
      height: 328px;
      width: 373px;
    }
  }

  .wrapper {

    .back {
      color: ${colors.white};
      margin-bottom: 50px;

      span {
        color: #ffffff;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 1px;
        margin-left: 9px;
      }

      &:hover {
        color: ${colors.white};
        text-decoration: none;
      }

      .back {
        color: ${colors.saffron};
      }

      @media (min-width: 768px) {
        margin-bottom: 66px;
      }
    }

    h1 {
      color: #ffffff;
      font-family: Roboto;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 47px;
      letter-spacing: normal;
      margin-bottom: 0;

      @media (min-width: 768px) {
        font-size: 64px;
        line-height: 75px;
      }

      @media (min-width: 1440px) {
      }
    }

    hr {
      height: 1px;
      margin-bottom: 16px;
    }

    .content {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 1px;
      margin-bottom: 0;

      @media (min-width: 992px) {
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: 0.6px;
        padding-right: 15px;
        margin-bottom: 0;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
`

Hero.propTypes = {
  data: object.isRequired
}

Hero.defaultProps = {
  data: {}
}

export default Hero
